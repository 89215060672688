import PropTypes from "prop-types";

/**
 * Vendor
 */
/** ========== */
const Title = ({ text, as, children, className, ...props }) => {
  const As = as;

  return (
    <As
      {...props}
      className={` ${
        className ? className : "text-bbc-40px font-semibold text-bbc-primary"
      }`}
    >
      {children ? (
        children
      ) : (
        <span dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </As>
  );
};

export default Title;

Title.propTypes = {
  text: PropTypes.string,
  as: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

Title.defaultProps = {
  text: null,
  as: "h2",
};

/** ========== */
