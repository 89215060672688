import PropTypes from "prop-types";

import { classNames } from "common/functions";

import Link from "components/atoms/LinkRouter";

/**
 *
 * @param {{
 * as: "a" | "button";
 * variant: "primary" | "outline" | "ghost";
 * btnTextWeight: "font-semibold";
 * btnColor: "bg-blue-500 hover:bg-blue-600" | "bg-transparent hover:bg-blue-500" | "bg-transparent";
 * btnPadding: "px-6 py-2";
 * btnBorder: "border border-blue-500" | "border-0";
 * btnRounded: "rounded-full";
 * onClick: function(){};
 * othersClass: "";
 * className: "";
 * }} props Props for the component
 *
 */
function ButtonNew({
  as,
  btnDisplay,
  useRouter,
  children,
  variant,
  btnColor,
  btnPadding,
  btnTextColor,
  btnTextWeight,
  btnBorder,
  btnRounded,
  othersClass,
  className,
  ...props
}) {
  const CustomTag = as;
  const commonStyles =
    "leading-[26px]  transition-all duration-200 disabled:select-none disabled:bg-bbc-neutral-700 disabled:hover:bg-bbc-neutral-700";
  let btnStyle = [];

  switch (variant) {
    case "primary":
      btnStyle = [
        btnDisplay || "inline-block ",
        btnTextWeight || "font-semibold",
        btnColor || "bg-bbc-brand-500 hover:bg-bbc-brand-600",
        btnRounded || "rounded-full",
        btnPadding || "px-6 py-2",
        btnTextColor || "text-white",
        othersClass,
      ].join(" ");
      break;

    case "outline":
      btnStyle = [
        btnDisplay || "inline-block ",
        btnTextWeight || "font-semibold",
        btnColor || "bg-transparent hover:bg-bbc-brand-500",
        btnRounded || "rounded-full",
        btnBorder || "border border-bbc-brand-500",
        btnPadding || "px-6 py-2",
        btnTextColor || "text-bbc-brand-500 hover:text-white",
        othersClass,
      ].join(" ");
      break;

    case "ghost":
      btnStyle = [
        btnDisplay || "inline-block ",
        btnTextWeight || "font-semibold",
        btnColor || "bg-transparent ",
        btnRounded || "rounded-full",
        btnPadding || "px-6 py-2",
        btnTextColor || "text-bbc-brand-500 hover:text-bbc-brand-600",
        othersClass,
      ].join(" ");
      break;
    default:
      break;
  }
  if (useRouter) {
    return (
      <Link
        className={className ? className : classNames(commonStyles, btnStyle)}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <CustomTag
      className={className ? className : classNames(commonStyles, btnStyle)}
      {...props}
    >
      {children}
    </CustomTag>
  );
}

ButtonNew.propTypes = {
  variant: PropTypes.string,
  useRouter: PropTypes.bool,
  children: PropTypes.any,
  as: PropTypes.string,
};
ButtonNew.defaultProps = {
  variant: "primary",
  useRouter: false,
  as: "button",
};

export default ButtonNew;
