import { createContext } from "react";

export const LayoutContext = createContext({});
LayoutContext.displayName = "LayoutContext";

export const BankingOption = createContext({});
BankingOption.displayName = "BankingOptionContext";

export const ModalPackageForm = createContext({});
ModalPackageForm.displayName = "ModalPackageFormContext";

export const StepHongKongTool = createContext({});
StepHongKongTool.displayName = "StepHongKongToolContext";

export const ComplianceTest = createContext({});
ComplianceTest.displayName = "ComplianceTestContext";

export const ToastContext = createContext({});
ToastContext.displayName = "ToastContext";

export const AccordionContext = createContext({});
AccordionContext.displayName = "AccordionContext";

export const GrantsAndIncentivesContext = createContext({});
GrantsAndIncentivesContext.displayName = "GrantsAndIncentivesContext";
