import { useContext } from "react";

import { LayoutContext } from "common/contexts";

const LinkRouter = ({ href, rel, children, className, ...props }) => {
  const { locationRouter } = useContext(LayoutContext);
  let url = `${locationRouter && "/"}${locationRouter}${href}`;

  if (url[url.length - 1] === "/") {
    url = url.slice(0, -1);
  }

  return (
    <a rel={rel} href={url} className={className} {...props}>
      {children}
    </a>
  );
};

export default LinkRouter;

/** ========== */
