export function formatDate(date) {
  return date
    ? `${new Date(date).getDate()} ${new Intl.DateTimeFormat("en-US", {
        month: "short",
      }).format(new Date(date))} ${new Date(date).getFullYear()}`
    : "";
}

// Join className String
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
