import { Component } from "react";

import ButtonNew from "components/atoms/ButtonNew";
import Title from "components/atoms/Title";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex h-screen flex-col items-center justify-center">
          <Title className="title-h2 mb-6 pb-0 text-center text-bbc-brand-800">
            We have a new update.
          </Title>
          <p> Press the button to reload the page</p>
          <ButtonNew
            type="button"
            othersClass="mt-6"
            onClick={() => {
              window.location.reload(true);
              this.setState({ hasError: false });
            }}
          >
            Reload
          </ButtonNew>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
