// Import { useEffect, useLayoutEffect } from "react";

import Head from "next/head";
import Script from "next/script";

import ErrorBoundary from "../components/templates/ErrorBoundary";

import "styles/globals.scss";
// eslint-disable-next-line react/prop-types

// Export const useBrowserLayoutEffect = process.browser
//   ? useLayoutEffect
//   : useEffect;

function MyApp({ Component, pageProps }) {
  // UseBrowserLayoutEffect(() => {
  //   Const handleNavigator = () => {
  //     Navigator.serviceWorker.getRegistrations().then((registrations) => {
  //       For (const registration of registrations) {
  //         Registration.unregister().then((bool) => {
  //           Console.log("unregister: ", bool);
  //         });
  //       }
  //     });
  //   };

  //   If (navigator && "serviceWorker" in navigator) {
  //     Window.addEventListener("load", handleNavigator);
  //   }

  //   Return () => {
  //     Console.log("clean");
  //     Window.removeEventListener("load", handleNavigator);
  //   };
  // }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="facebook-domain-verification"
          content="t6ecnryhghaszvxhpm0phfa9oy6vgv"
        />
        {/* <script type="text/javascript" src="/console-warning.js"></script> */}
      </Head>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>

      {process.env.NEXT_PUBLIC_GTM_ENABLE && (
        <>
          <Script strategy="lazyOnload" id="Tawk_API">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}
            {
              "(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,175667,190245);"
            }
            {`var Tawk_LoadStart=new Date();(function(){var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];s1.async=true;s1.src='${process.env.NEXT_PUBLIC_SRC_TAWKTO}';s1.charset='UTF-8';s1.setAttribute('crossorigin','*');s0.parentNode.insertBefore(s1,s0)})();
            function getGAUserID(){try{var trackers=ga.getAll();var i,len;for(i=0,len=trackers.length;i<len;i+=1){if(trackers[i].get("trackingId")==="${process.env.NEXT_PUBLIC_GA_ID}"){return trackers[i].get("clientId")}}}catch(e){}return false}
            var clientId = "";
            window.Tawk_API = window.Tawk_API || {};
            window.Tawk_API.onLoad = function() {
              clientId = getGAUserID();
            }
            window.Tawk_API.onPrechatSubmit = function(data){
              var name;var email;var client_id=clientId;for(var i=0;i<data.length;i++){if(data[i].label=="Name"){name=data[i].answer}else if(data[i].label=="Email"){email=data[i].answer}}window.dataLayer=window.dataLayer||[];window.dataLayer.push({'event':'tawkto','name':name,'email':email,});const xhttp=new XMLHttpRequest();xhttp.open("POST","${process.env.NEXT_PUBLIC_ORDER_API_ENDPOINT}/api/portal/contact_form/tawkto_api");xhttp.setRequestHeader("Content-type","application/json");xhttp.send(JSON.stringify({'first_name':name,'email':email,'website_id':${process.env.NEXT_PUBLIC_WEBSITE_ID},'client_id':client_id,'submit_tawkto_from':'webiste_nextjs'}));fbq('track','Live_chat_submit');
            };
            Tawk_API.onOfflineSubmit = function(data){
              var data_offline=data.questions;var name;var email;var client_id=clientId;for(var i=0;i<data_offline.length;i++){if(data_offline[i].label=="Name"){name=data_offline[i].answer}else if(data_offline[i].label=="Email"){email=data_offline[i].answer}}window.dataLayer=window.dataLayer||[];window.dataLayer.push({'event':'tawkto','name':name,'email':email,});const xhttp=new XMLHttpRequest();xhttp.open("POST","${process.env.NEXT_PUBLIC_ORDER_API_ENDPOINT}/api/portal/contact_form/tawkto_api");xhttp.setRequestHeader("Content-type","application/json");xhttp.send(JSON.stringify({'first_name':name,'email':email,'website_id':${process.env.NEXT_PUBLIC_WEBSITE_ID},'client_id':client_id,'submit_tawkto_from':'webiste_nextjs'}));fbq('track','Live_chat_submit');
            };
            Tawk_API.onChatMaximized=function(){document.getElementsByClassName("hidd")[0].style.display="block"};
            Tawk_API.onChatMinimized=function(){document.getElementsByClassName("hidd")[0].style.display="none"}`}
          </Script>
          <Script
            id="afterInteractive"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "9y6vlr6524");
            `,
            }}
          />

          <Script
            id="duns-seal-script"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
              (function(){
               var url = window.location;
               let dunsElement = document.getElementById('duns-seal');
               if(dunsElement){
                if (url.protocol == "http:"){
                  dunsElement.innerHTML ="<iframe id='Iframe1' src='http://dunsregistered.dnb.com/SealAuthentication.aspx?Cid=1' width='114px' height='97px' frameborder='0' scrolling='no' allowtransparency='true' ></iframe>";
                } else {
                  dunsElement.innerHTML ="<iframe id='Iframe1' src='https://dunsregistered.dnb.com/SealAuthentication.aspx?Cid=1' width='114px' height='97px' frameborder='0' scrolling='no' allowtransparency='true' ></iframe>";
                }
               }
              })();
            `,
            }}
          ></Script>
        </>
      )}
    </>
  );
}

export default MyApp;
